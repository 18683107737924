
#slide23 .slide-copy-wrap {
    width: 78%;
}
#slide23 .slide-copy {
    width: 100%;
    margin-top: 40px;
}
#slide23 .slide-copy ul li {
    font-size: 22px;
    text-align: left;
    margin-bottom: 0px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath id='Path_186' class='st0' d='M2.1,2.6C0.1,4.9-0.1,7.5,1.7,9C3.4,10.4,6,9.8,7.9,7.5s2.1-4.9,0.4-6.4C6.5-0.4,4,0.2,2.1,2.6' /%3E%3C/svg%3E");
    background-position: top 10px left;
}

#slide23 .image-wrap {
    width: 100%;
    margin-top: 20px;
}
#slide23 .col-wrap {
    width: 100%;
    /* max-width: 966px; */
    display: flex;
    justify-content: space-between;
}
#slide23 .image-col {
    width: 32.333333%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 20px 20px 20px;
    background: rgba(0,0,2,0.3);
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(0,0,37,0) 60%);
    cursor: pointer;
    -webkit-transition: all 800ms ease;
    -moz-transition: all 800ms ease;
    -ms-transition: all 800ms ease;
    -o-transition: all 800ms ease;
    transition: all 800ms ease;
}
#slide23 .image-col:hover {
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(255,255,255,.2) 90%);
}
#slide23 .image-col:hover > .expand {
    background-color: #4F4F80;
}
#slide23 .image-col img {
    width: 100%;
    margin-top: 10px;
    pointer-events: none;
}
#slide23 .image-col p {
    font-size: 18px;
    text-align: left;
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    pointer-events: none;
}

#slide23 .expand {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000025;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='18,9.5 10.5,9.5 10.5,2 9.5,2 9.5,9.5 2,9.5 2,10.5 9.5,10.5 9.5,18 10.5,18 10.5,10.5 18,10.5 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
}

.popup-img-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
}
.popup h2 {
    color: #FFFFFF;
}
.popup-img-wrap img {
    width: 540px;
}
