#slide22 .image-wrap {
    width: 100%;
    margin-top: 30px;
}
#slide22 .slide-copy {
    width: 75%;
    margin-top: 40px;
}
#slide22 .image-wrap img {
    width: 100%;
    display: block;
}
#slide22 .col-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#slide22 .image-col {
    width: 50%;
}
#slide22 .text-col {
    width: 46%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;
}
#slide22 .fade {
    padding: 10px;
    background: rgba(0,0,2,0.3);
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(0,0,37,0) 60%);
}
#slide22 .image-col img {
    width: 100%;
    margin-bottom: 30px;
}
#slide22 .text-col p {
    font-size: 22px;
    text-align: left;
    margin-top: auto;
}


