.slide {
    max-width: 1366px;
    width: 100%;
    min-height: 718px;
    /* background-color: rgba(255, 255, 255, 0.2); */
    padding: 20px 30px;
    position: relative;
}
.slide h1{
    font-size: 36px;
}
.slide h1::after{
    content: url("../../../assets/images/fade-line.svg");
    position: absolute;
    margin-top: 25px;
    left: 0px;
    width: 100px;
    height: 4px;
}
.video-wrap {
    max-width: 280px;
    width: 100%;
    position: absolute;
    top: -25px;
    right: 25px;
    margin: 0;
    z-index: 1000;
}
.modalBtn {
    width: 100px;
    padding: 20px;
    background-color: black;
    cursor: pointer;
}
.slide-copy {
    width: 80%;
    margin-top: 20px;
    /* background-color: rgba(255, 255, 255, 0.2); */
}
.slide-copy p {
    font-size: 22px;
    line-height: 1.2;
}
.slide-copy p > span {
    color: #FF6C0E;
}

.gfx {
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgba(0,0,2,0.3);
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(0,0,37,0) 60%);
    /* background: linear-gradient(to bottom, rgba(0,0,2,0.3) 0%,rgba(75,111,140,0.3) 60%,rgba(125,185,232,0) 100%); */
}
.gfx img {
    display: block;
    width: 95%;
}
