* {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: rgba(113, 19, 113, 0.05);
  /* make transparent link selection, adjust opacity 0 to 1.0 */
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;

  /* -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease; */
}

html, body {

  background: rgb(0,0,37);
  background: linear-gradient(180deg, rgba(0,0,37,1) 0%, rgba(0,47,85,1) 100%);

    
  /* background-color: #033056; */
  background-color: #000025;
  /* background-color: #FFFFFF; */
  width: 100%;
  height: 100%;
}

.restart {
  width: 240px;
  height: 70px;
  position: absolute;
  bottom: 0;
  right: 0;
  /* background-color: rgba(0, 0, 0, 0.05); */
  z-index: 1001;
}

.site {
  width: 1366px;
  height: 768px;
  position: relative;
  display: flex;
  justify-content: center;
  
}

.wrapper {
  /* max-width: 1366px; */
  width: 1366px;
  height: 768px;
  position: relative;
  background-color: #002F55;
}

p {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 1.4;
  color: #ffffff;
  /* color: #033056; */
  margin-bottom: 10px;
}
p span {
  color: #FF6C0E;
}



h1 {
  font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
  font-size: 36px;
  line-height: 1.2;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  /* margin-bottom: 10px; */
}

h1.underline {
  /* display: block; */

  font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
  font-size: 36px;
  line-height: 1.2;
  font-weight: bold;
  color: #ffffff;
}
h1.underline::after{
  content: url("./assets/images/fade-line.svg");
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100px;
  height: 4px;
}

h2 {
  font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 1.4;
  font-weight: normal;
  color: #ffffff;
  /* margin-bottom: 10px; */
}

/*UNORDERED LIST*/
ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
li {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  /* margin-bottom: 10px; */
  /* margin: 0px 0px 20px 0px; */
  cursor: pointer;
}
ul li {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23002F55;%7D%0A%3C/style%3E%3Ccircle class='st0' cx='3' cy='5' r='3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 0px 4px; 
  padding-left: 20px;
  /* margin-bottom: 5px; */
}
ul li span {
  color: #FF6C0E;
}


ul li ul{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #033056;
  /* margin-bottom: 10px;
  margin: 0px 0px 20px 0px; */
}
ul li ul li {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D2B4E;%7D%0A%3C/style%3E%3Crect y='4.5' class='st0' width='5' height='1'/%3E%3C/svg%3E");  background-size: 5px;
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: 0px 6px; 
  padding-left: 20px;
  margin-bottom: 5px;
}
ul li ul li span {
  color: #FF6C0E;
}
.txt-center {
  text-align: center !important;
}

.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}

sub, sup {
	vertical-align: baseline;
	position: relative;
	font-size: 60%;
}
sub {
	bottom: -0.6em;
}
sup {
	top: -0.6em
}

.flex-center {
  display: flex;
  justify-content: center;
}


/* ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  color: #033056;
  margin-bottom: 10px;
  margin: 0px 0px 20px 0px;
}
ul li {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D2B4E;%7D%0A%3C/style%3E%3Crect y='4.5' class='st0' width='5' height='1'/%3E%3C/svg%3E");  background-size: 5px;
  background-repeat: no-repeat;
  background-size: 9px;
  background-position: 0px 6px; 
  padding-left: 20px;
  margin-bottom: 5px;
} */

.btn {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  padding: 15px 80px;
  background-color: #943AF4;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.btn:hover {
  background-color: #CA9DFA;
}

.debug-wrap {
  width: 100%;
  max-width: 560px;
  padding: 20px;
  position: fixed;
  top: 120px;
  left: 20px;
  background-color: black;
  color: #ffffff;
  z-index: 10000;
}

.link-out {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {

  p {

    font-size: 14px;
  }
  
  h1 {
    font-size: 40px;
  }
  
  h2 {
    font-size: 20px;
  }

}


/* .swiper-container {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
} */