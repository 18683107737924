#intro {
    max-width: 1366px;
    width: 100%;
    min-height: 768px;
    padding: 0px;
}
.intro {
    width: 100%;
    padding: 70px 30px 30px 30px;
}
.intro-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
}
.intro-wrap h1 {
    font-size: 36px;
    margin: 0;
}
.intro-wrap h2 {
    font-size: 28px;
    /* margin-bottom: 10px; */
}
.fade-line {
    width: 100%;
    height: 1px;
    margin: 30px 0px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,1+70,0+100 */
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}
.intro-wrap p {
    font-size: 18px;
}
.intro-wrap p.footnote {
    font-size: 14px;
  }
.intro-wrap .intro-column {
    width: 48%;
}
.intro-wrap .column1 {
    padding-left: 60px;
}
.intro-wrap .column2 {
    padding-right: 60px;
}
.intro-wrap .column2 p {
    margin-bottom: 0px;
}
.intro-video-wrap {
    width: 100%;
    margin-bottom: 20px;
}