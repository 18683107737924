#slide12 .outer-wrap {
    width: 100%;
    padding: 20px;
    margin-top: 100px;
}
#slide12 .list-wrap {
    width: 100%;
}
#slide12 .col-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#slide12 .text-col {
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
#slide12 .text-col-2 {
    width: 49.4%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
#slide12 .text-col-2 .txt {
    margin-left: 20px;
}
#slide12 .text-col-2 .txt h2 {
    line-height: 1;
    margin: 0;
}
#slide12 .text-col-2 .txt p {
    line-height: 1;
    margin: 0;
}
#slide12 .fade {
    position: relative;
    padding: 20px 20px;
    background: rgba(0,0,2,0.3);
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(0,0,37,0) 60%);
}
#slide12 .text-col h2 {
    font-size: 48px;
    line-height: 1;
    margin-bottom: 0px;
    text-align: center;
}
#slide12 .text-col p {
    /* font-size: 22px; */
    text-align: center;
    line-height: 1.2;
}
#slide12 .text-col ul li {
    font-size: 22px;
    text-align: left;
    margin-bottom: 20px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath id='Path_186' class='st0' d='M2.1,2.6C0.1,4.9-0.1,7.5,1.7,9C3.4,10.4,6,9.8,7.9,7.5s2.1-4.9,0.4-6.4C6.5-0.4,4,0.2,2.1,2.6' /%3E%3C/svg%3E");
    background-position: top 10px left;
}

#slide12 .Icon-type-1 {
    width: 126px;
    margin-bottom: 20px;
}
#slide12 .Icon-type-2 {
    width: 80px;
}
#slide12 .arrow {
    width: 70px;
    position: absolute;
    left: -42px;
    top: 146px;
}


