.header {
    width: 100%;
    /* height: 50px; */
    display: flex;
    z-index: 100;
}
.menu {
    height: 50px;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    z-index: 100;
    position: relative;
}
.menu-column {
    position: relative;
}

.level1 {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    line-height: 1.4;
    color: #ffffff;
    cursor: pointer;
    background-image: none;
    padding: 8px 20px;
}
.level2 {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    line-height: 1.4;
    color: #033056;
    cursor: pointer;
    background-image: none;
    padding: 8px 10px;
}
.level2:hover {
    color: orange;
}


/* 
.menu li {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    line-height: 1.4;
    color: #ffffff;
    cursor: pointer;
    background-image: none;
} */
.level1.menu-item-active {
    color: #FF6C0E;
}

.submenu {
    min-width: 300px;
    position: absolute;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    top: 50px;
    left: 10px;
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    line-height: 1.4;
    color: #033056;
    cursor: pointer;
    -webkit-box-shadow: 1px 2px 3px 5px rgba(0,0,0,.2);
    -moz-box-shadow: 1px 2px 3px 5px rgba(0,0,0,.2);
    box-shadow: 1px 2px 3px 5px rgba(0,0,0,.2);
    z-index: 1;
    display: none;
}


.triUp {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    border-left: 10px solid transparent;
    position: absolute;
    top: -20px;
    left: 20px;
}
.active-sub {
    display: block;
    opacity: 1
}
.menu-blocker {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.2);
    z-index: 100;
    display: none;
}
.show-blocker {
    display: block;
}
