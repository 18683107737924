#slide40 .list-wrap {
    width: 100%;
    margin-top: 50px;
}
#slide40 .col-wrap {
    width: 90%;
    display: flex;
    justify-content: space-between;
}
#slide40 .text-col {
    width: 75%;
}
#slide40 .fade {
    position: relative;
    padding: 30px 40px;
    background: rgba(0,0,2,0.3);
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(0,0,37,0) 60%);
}
#slide40 .text-col h2 {
    margin-bottom: 20px;
}
#slide40 .text-col p {
    font-size: 22px;
    text-align: left;
    margin-top: auto;
}
#slide40 .text-col ul li {
    font-size: 22px;
    text-align: left;
    margin-bottom: 20px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath id='Path_186' class='st0' d='M2.1,2.6C0.1,4.9-0.1,7.5,1.7,9C3.4,10.4,6,9.8,7.9,7.5s2.1-4.9,0.4-6.4C6.5-0.4,4,0.2,2.1,2.6' /%3E%3C/svg%3E");
    background-position: top 10px left;
}
#slide40 .text-col ul ul li {
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 2' style='enable-background:new 0 0 10 2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Benable-background:new ;%7D .st1%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg class='st0'%3E%3Cpath class='st1' d='M0,0.1h10v1.9H0V0.1z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: top 14px left;
}
#slide40 .top-rule {
    width: 100%;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255,108,14);
    background: linear-gradient(90deg, rgba(255,108,14,1) 0%, rgba(148,58,244,1) 50%, rgba(148,58,244,0) 100%);
}
#slide40 .left-rule {
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255,108,14);
    background: linear-gradient(180deg, rgba(255,108,14,1) 0%, rgba(148,58,244,1) 50%, rgba(148,58,244,0) 100%);
}


