
.nav-wrap {
    width: 100%;
    display: flex;
    align-items:center ;
}
.next-back {
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
}
.button-parent {
  width: 100%;
  position: absolute;
  bottom: 15px;
  z-index: 1000;
}
.button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-btn {
    width: 46px;
    height: 46px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Group_467' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 46 46' style='enable-background:new 0 0 46 46;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D .st1%7Bfill:%23002F55;%7D%0A%3C/style%3E%3Ccircle id='Ellipse_3' class='st0' cx='23' cy='23' r='23'/%3E%3Cpolygon class='st1' points='20.4,32.4 17.6,29.6 24.2,23 17.6,16.4 20.4,13.6 29.8,23 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 46px;
    background-position: center;
    cursor: pointer;
}
.swiper-btn-disabled {
    opacity: .5;
    pointer-events: none;
    cursor: none;
}
#swiper-prev-btn {
    margin-right: 10px;
    transform: rotate(-180deg);
}
#swiper-next-btn {
    margin-left: 10px;
}

#containerForBullets {
    width: auto;
    display: flex;
}
.swiper-custom-bullet {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #ffffff;
    display: flex;
    margin: 0px 5px;
    cursor: pointer;
  }
  .swiper-custom-bullet.swiper-custom-bullet-active {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 1);
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    width: auto;
    display: flex;
  }

  .swiper-backface-hidden .swiper-slide {
    max-width: 1366px !important;
    width: 100% !important;
  }

  .swiper-autoheight, .swiper-autoheight .swiper-slide {
    width: 100%;
  }

  .swiper {
    overflow: unset;
}

