#home {
    max-width: 1366px;
    width: 100%;
    min-height: 768px;
    padding: 80px;
    background-image: url("../../assets/images/home-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.guerbet-logo {
    width: 275px;
    height: 45px;
}
.geurbet-logo img {
    width: 100%;
}
.hompage-text h1 {
    font-size: 80px;
    line-height: 1;
    margin: 0;
}
.hompage-text h2 {
    font-size: 32px;
    line-height: 1;
    text-transform: uppercase;
    margin: 0;
}
.hompage-text p {
    font-size: 18px;
    margin: 0;
}
.hompage-text {
    margin-top: 20%;
}
.enter-btn {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 16px !important;
    line-height: 1;
    text-transform: uppercase;
    background-color: #943AF4;
    border: 2px solid #943AF4;
    color: #ffffff;
    display: inline-block;
    border-radius: 60px;
    padding: 15px 60px;
    margin: 20px 0px;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.enter-btn:hover {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    border: 2px solid #ffffff;
}