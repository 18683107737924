#slide00 .slide-copy-wrap {
    width: 100%;
    margin-top: 80px;
}

#slide00 .slide-copy {
   width: 100%;
   max-width: 768px;
}

#slide00 .image-wrap {
    width: 100%;
    margin-top: 20px;
}
#slide00 .col-wrap {
    width: 100%;
    max-width: 966px;
    display: flex;
    justify-content: space-between;
}
#slide00 .image-col {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: rgba(0,0,2,0.3);
    background: linear-gradient(180deg, rgba(0,0,37,.3) 0%, rgba(0,0,37,0) 60%);
}
#slide00 .image-col img {
    width: 182px;
    margin-bottom: 30px;
}
#slide00 .image-col p {
    font-size: 22px;
    text-align: center;
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
}


