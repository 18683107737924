#slide10 .image-wrap {
    width: 100%;
    margin-top: 30px;
}
#slide10 .slide-copy {
    width: 75%;
    margin-top: 40px;
}
#slide10 .image-wrap img {
    width: 100%;
    display: block;
}
