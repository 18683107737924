/* .footer {
    max-width: 1366px;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
}
.footer-wrap {
    width: 100%;
    padding: 10px 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.footnotes {
    width: 37%;
}
.footnotes-wide {
    width: 40%;
}
.footer-wrap p {
    font-size: 11px;
    margin: 0;
}
.logo {
    width: 199px;
    height: 42px;
    margin-right: 10px;
    margin-bottom: 10px;
} */


.footnotes {
    width: 37%;
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 10000;
}

.footnotes p {
    font-size: 11px;
    margin: 0;
}

a { color: #ffffff; }
a:link { color: #ffffff; }
a:visited { color: #ffffff; }
a:hover { color: #ffffff; }
a:active { color: #ffffff; }


.logo {
    width: 199px;
    height: 42px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}